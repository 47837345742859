import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import * as React from "react";

export default function RegDialog(props) {
  const [sect, setSect] = React.useState("");
  const [userType, setUserType] = React.useState(null);
  const [count, setCount] = React.useState(0);

  const { handleClose, dialogOpen } = props;

  React.useEffect(() => {
    if (count === 0) {
      setSect("");
    }
  }, [count, setSect]);

  function saveData() {
    if (userType) {
      handleClose(userType);
      setCount(0);
    }
  }

  function cancelData() {
    handleClose(null);
    setCount(0);
    setUserType(null);
    setSect("");
  }

  return (
    <Dialog open={dialogOpen}>
      <DialogContent style={{ fontSize: 18 }}>
        <div>{props.texts.usertype}</div>
        <FormGroup>
          <RadioGroup
            aria-labelledby="demo"
            name="user_type"
            value={userType}
            row
            onChange={(e) => setUserType(e.target.value)}
          >
            <FormControlLabel
              value="student"
              control={<Radio />}
              label={props.texts.visitor}
            />
            <FormControlLabel
              value="org"
              control={<Radio />}
              label={props.texts.org}
            />
           
          </RadioGroup>
        </FormGroup>
      </DialogContent>
      <DialogActions>
        <Button onClick={saveData}>{props.texts.enter}</Button>
        <Button onClick={cancelData}>{props.texts.cancel}</Button>
      </DialogActions>
    </Dialog>
  );
}

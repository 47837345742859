import React from "react";
import PropTypes from "prop-types";
import { userService } from "../_services";
import { serverUrl } from "../_constants";

import "../css/style.css";

import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";

import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";

import i18next from "i18next";

export class SettingsPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      req_list: [],
      req_data: [],
      tariff_id: 0,
      tariff_name: "",
      tariff_actions: null,
      tariff_expire: null,
      balance: "0",
      add_money: "0",
    };
  }

  componentDidMount() {
    //{{naviconf_api_url}}/api/user


    this.getTarifInfo();
	this.getUserInfo();
   
  }

  getUserInfo = () => {

	
    let self = this;

	userService.getWrapper(
		"api/user",
		(data) => {
		  console.log(data.data);
  
		  let req_list = Object.values(data.data.all_requisites);
		  let req_keys = Object.keys(data.data.all_requisites);
  
		  let req_data = [];
		  let req_id = [];
		  for (let i = 0; i < req_list.length; i++) {
			req_data[i] = { id: "", val: "" };
		  }
  
		  console.log(req_data.length);
  
		  for (let j = 0; j < data.data.requisites.length; j++) {
			let req_id = data.data.requisites[j].requisite_key_id + "";
			let id = data.data.requisites[j].id + "";
			let val = data.data.requisites[j].value;
  
			let new_id = req_keys.indexOf(req_id);
			if (new_id >= 0) req_data[new_id] = { id: id, val: val };
		  }
  
		  self.getTarifsList(data.data.tariff_id);
		  this.setState({
			req_list,
			req_data,
			balance: data.data.balance,
			tariff_id: data.data.tariff_id,
			tariff_expire: data.data.tariff_expire,
			id: data.data.id,
		  });

		},
		(error) => {
		  console.log(error);
		}
	  );
  }

  getTarifsList = (tariff_id) => {
    userService.getWrapper(
      "api/tariff",
      (data) => {
        let tariff_info = data.data.filter((e) => e.id == tariff_id);

        if (tariff_info.length > 0)
          this.setState({
            tariff_name: tariff_info[0].name,
            tariff_actions: tariff_info[0].tariff_actions,
          });
      },
      (error) => {
        console.log(error);
      }
    );
  };

  addMoney = () => {
    const { add_money, id } = this.state;
    let self = this;

    if (add_money<=0)
      return;

    let url = `${serverUrl}replenish-balance/${id}?sum=${add_money}`
    window.location.href = url;
  }

  getTarifInfo = () => {
    userService.getWrapper(
      "api/tariff/restriction/user",
      (data) => {
        const keys = Object.keys(data.data);
        const result = keys.length > 0 ? keys.map(function(key) {
          const max = data.data[key]['max'] ?? i18next.t("unlimited");
          return <div>{key}: {data.data[key]['current'] ?? 0} / {max}</div>;
        }) : i18next.t("unlimited");
        this.setState({orgResriction: <div>{result}</div>});
      },
      (error) => {
        console.log(error);
      }
    );
  };

  setReq = (index, val) => {
    let { req_data } = this.state;

    req_data[index].val = val;
    this.setState({ req_data });
  };

  onSave = () => {
    let upd_data = { requisites: [] };

    const { req_list, req_data } = this.state;

    for (let i = 0; i < req_list.length; i++) {
      if (req_data[i].id || req_data[i].val) {
        let tmp_obj = {
          requisite_key_id: "" + (i + 1),
          value: req_data[i].val,
          action: req_data[i].id ? "update" : "insert",
          is_default: 0,
        };
        if (req_data[i].id) tmp_obj.id = req_data[i].id;

        upd_data.requisites.push(tmp_obj);
      }
    }

    userService.patchWrapperBody(
      "api/user",
      upd_data,
      (data) => {
        console.log(data);
      },
      (error) => {
        console.log(error);
      }
    );
  };

  render() {
    const self = this;
    const {
      isLoading,
      req_list,
      req_data,
      tariff_name,
      tariff_expire,
      balance,
	  add_money
    } = this.state;

    console.log(this.state);

    return (
      <div className="layout">
        <h1 className="layout__title">{i18next.t("settings")}</h1>
        <div>Используется корпоративная лицензия</div>
        
        <div style={{ marginLeft: 10, marginTop: 20 }}>
          <h3 className="layout__title">{i18next.t("company_details")}</h3>
          {req_list.map(function (item, index) {
            return (
              <div
                style={{
                  display: "flex",
                  flex: 1,
                  flexDirection: "row",
                  marginBottom: 10,
                  aligItems: "center",
                }}
              >
                <div
                  style={{
                    minWidth: 400,
                    marginRight: 10,
                    fontSize: 16,
                    paddingTop: 10,
                  }}
                >
                  {item}
                </div>
                <input
                  placeholder={item}
                  value={req_data[index] ? req_data[index].val : ""}
                  onChange={(e) => self.setReq(index, e.target.value)}
                />
              </div>
            );
          })}
        </div>
        <Button
          variant="contained"
          onClick={this.onSave}
          style={{ marginTop: 10 }}
        >
          Сохранить
        </Button>
      </div>
    );
  }
}
